/* You can add global styles to this file, and also import other style files */
@import 'theme.scss';

// GLOBAL VARS
:root {
  --headerBackground: url('https://firebasestorage.googleapis.com/v0/b/hallo-bob.appspot.com/o/images%2FbobBackground.jpg?alt=media&token=a8a89b33-247f-475f-929d-3f238e8c4d94');
  --primary: #3470ca;
  // --accent:
  --logo: url('https://firebasestorage.googleapis.com/v0/b/hallo-bob.appspot.com/o/images%2FBob_de_Bot.png?alt=media&token=c955a823-42d1-40d5-8e5f-f27de53d6670');
  --loginVideo: url('https://firebasestorage.googleapis.com/v0/b/hallo-bob.appspot.com/o/background%20%2FFixed_Bob_de_Bot_banner.mp4?alt=media&token=b0034d0c-e8ad-41d8-b541-c1f77a9d7c29');
}
