$header-height: 64px;

html,
body {
  height: 100%;
}

body {
  margin: 0;
}

// *:not(mat-icon) {
//   font-family: Montserrat-Regular, 'Helvetica Neue', sans-serif;
// }

// .mat-slide-toggle-content,
// .mat-menu-item {
//   font-family: Montserrat-Regular, 'Helvetica Neue', sans-serif !important;
// }

*:not(mat-icon) {
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

.mat-slide-toggle-content,
.mat-menu-item {
  font-family: Roboto, 'Helvetica Neue', sans-serif !important;
}

.mat-icon-button {
  line-height: normal !important;
}

.fullscreen-dialog {
  width: calc(100% - 16px) !important;
  min-width: calc(100% - 16px) !important;
  max-width: calc(100% - 16px) !important;
  height: calc(100% - 16px) !important;
  min-height: calc(100% - 16px) !important;
  max-height: calc(100% - 16px) !important;
  .mat-dialog-container {
    background-color: #fefcf4 !important;
    min-height: 100%;
    max-height: 100%;
  }
}

.title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  font-family: Viga !important;
  margin: 16px 16px 6px 16px;
}

.large-title {
  font-size: 36px;
  color: var(--master);
}

.large-subtitle {
  font-size: 24px;
  color: var(--accent);
}

.normal-title {
  font-size: 24px;
  color: var(--primary);
}

.mat-dialog-container {
  border: solid 1px rgb(0, 0, 0, 0.6);
  background-color: #fefcf4 !important;
}

.mat-dialog-title {
  font-size: 24px;
  color: #40484b;
}

.dialog-subtitle {
  font-size: 16px;
  color: #40484b;
}

.dialog-text {
  font-size: 14px;
}

.card-wrapper {
  padding: 12px;
}

.card-outline,
.card-filled,
.card-dashboard {
  border: solid 1px rgb(0, 0, 0, 0.3);
  box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px !important;
  padding: 0 !important;
}

.card-background {
  background-color: #fefcf4 !important;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px !important;
  border-radius: 24px !important;
}

.card-outline {
  border-radius: 24px !important;
  background-color: #fefcf4 !important;
  .warnings {
    color: var(--accent);
    position: absolute;
    right: 8px;
    top: 16px;

    mat-icon {
      margin-right: 8px;
    }
  }

  mat-card-content {
    height: 225px;
    border-radius: 24px;
    background-color: #efefef;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .footer {
    padding: 0 16px 8px 16px;
    box-sizing: border-box;
  }
}

.card-filled {
  border-radius: 12px !important;
  background-color: #e5e3db !important;
  cursor: pointer !important;
  .warnings {
    color: var(--warning);
    position: absolute;
    right: 35px;
    top: 14px;
  }

  mat-card-content {
    height: 225px;
    border-radius: 12px;
    background-color: #fefcf4;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    .card-menu-button {
      z-index: 30;
      position: absolute;
      right: 5px;
      top: 5px;
    }
  }
  .footer {
    padding: 0 16px 8px 16px;
    box-sizing: border-box;
    color: #40484b;
    min-height: 60px;
  }
}

.card-filled-image {
  border-radius: 24px !important;
  background-color: #e5e3db !important;
  box-shadow: none !important;
  cursor: pointer;
  padding: 0 !important;
  width: 250px;
  .mat-card-content {
    .card-image {
      width: 100%;
      height: 188px;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      border-top-left-radius: 24px;
      border-top-right-radius: 24px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.3);
    }
    .footer {
      padding: 15px;
      font-weight: 900;
      color: #40484b;
      font-size: 14px;
      min-height: 40px;
    }
  }
}

//TEMP UNTIL NEW DASHBOARD
.card-dashboard {
  border-radius: 24px !important;
  background-color: #fefcf4 !important;
  cursor: pointer;
  .mat-icon-button {
    z-index: 30;
    position: absolute;
    right: 8px;
    top: 8px;
  }
  mat-card-content {
    background-color: #efefef !important;
    border-radius: 24px;
    height: 120px;
    .icon-wrapper {
      width: 100%;
      text-align: center;
      .support {
        font-size: 56px;
        width: 56px;
        height: 56px;
      }
    }
    .statistics,
    .mat-icon {
      margin: 0 auto 0 auto;
      padding-top: 30px;
      max-width: 134px;
      text-align: center;
      .statistic {
        font-size: 36px;
        font-weight: bold;
        color: var(--primary);
      }
      .subtitle {
        max-width: none;
      }
    }
    .mat-menu-trigger {
      position: absolute;
      right: 8px;
      top: 8px;
    }
  }
  .footer {
    .title {
      margin: 16px !important;
      font-size: 16px;
      color: var(--primary);
    }
  }
}
//TEMP UNTIL NEW DASHBOARD

.outlined-button,
.filled-button,
.filled-tonal-button,
.disabled-button,
.warning-button {
  font-size: 13px !important;
  font-weight: 600 !important;
  padding: 8px 12px 8px 12px !important;
  line-height: normal !important;
  width: inherit !important;
  .mat-button-wrapper {
    .icon {
      font-size: 15px;
      height: 15px;
      width: 15px;
    }
  }
}

.outlined-button {
  display: flex !important;
  align-items: center !important;
  background-color: rgba(0, 0, 0, 0) !important;
  border: 1px solid black !important;
  color: #1a697f !important;
  .mat-button-wrapper {
    line-height: normal !important;
    .icon {
      color: #1a697f !important;
      border: 0 !important;
      margin-right: 0 !important;
    }
  }
}

.filled-button {
  background-color: var(--primary) !important;
  color: var(--primary-contrast) !important;
}

.disabled-button {
  background-color: rgba(0, 0, 0, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.warning-button {
  background-color: #f44336 !important;
  color: #ffffff !important;
  .mat-button-wrapper {
    .icon {
      color: var(--accent-contrast) !important;
      border: 0 !important;
      margin-right: 0px !important;
    }
  }
}

.filled-tonal-button {
  background-color: var(--accent-tonal) !important;
  color: var(--accent-tonal-contrast) !important;
  line-height: normal !important;
  width: inherit !important;
  .mat-button-wrapper {
    .icon {
      color: var(--accent-tonal-contrast) !important;
      border: 0 !important;
      margin-right: 0px !important;
    }
  }
}

.cal-event {
  color: #000 !important;
  cursor: pointer !important;
}

.cal-draggable {
  cursor: default !important;
}

// Floating action button bottom rightover entire page.
.fab-fixed {
  position: fixed !important;
  right: 16px;
  bottom: 16px;
  margin: 0 !important;
  z-index: 40;
}

.icon {
  height: 24px;
  color: rgba(0, 0, 0, 0.33);
  border-width: 1px;
  border-radius: 2px;
  border-style: solid;
  margin-right: 4px;
  padding: 2px;

  svg {
    height: 24px;
    width: 24px;
  }
}

// .mat-raised-button {
//   text-transform: uppercase;
// }
.widget {
  background-color: #f3f1e9 !important;
  margin: 6px;
  .mat-card-header-text {
    margin: 0;
  }
  .mat-card-header .mat-card-title {
    margin-bottom: 6px;
  }
}

.icon.active {
  color: var(--primary);
}

/* WebKit and Blink */
::-webkit-scrollbar {
  width: 6px;
  cursor: pointer;
}

::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.4);
  border-radius: 4px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.5);
}

@media (max-width: 599px) {
  .page {
    margin-bottom: 56px;
  }
}

@media print {
  .mat-sidenav-content {
    background-color: white;
  }

  .bottom-navigation {
    visibility: hidden;
  }
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.spinner:before {
  content: '';
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;
  border: 2px solid #ffffff;
  border-top-color: #000000;
  animation: spinner 0.8s linear infinite;
}
